import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Carousel from 'react-bootstrap/Carousel'
import CarouselHand from '../icons/CarouselHand'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 6.154em 1.5em 2em;
  @media screen and (min-width: 62em) {
    max-width: 1040px;
    width: 100%;
    padding: 5em 0 2em 0;
  }
`
const CoverImg = styled(Img)`
  height: 100%;
  widht: 100%;
`
const CoversCarousel = styled.div`
  display: block;
  .carouselIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.8rem 0 0;
    p {
      color: #8e8e8e;
      font-size: 0.93rem;
      font-weight: 400;
      letter-spacing: 1.5px;
      line-height: 37px;
  }
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: none;
  }
`
const MediumCovers = styled.div`
  display: none;
  @media screen and (min-width: ${props =>
      props.theme.responsive.medium}) and (max-width: ${props =>
      props.theme.responsive.large}) {
    display: block;
    margin-top: 40px;
  }
`

const LargeCovers = styled.div`
  display: none;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: 80px;
    display: block;
  }
`
const CoverInfo = styled.div`
  height: 100%;
  background-color: rgba(0, 80, 255, 0.95);
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(5px);
  transition: transform 0.5s ease, opacity 0.5s ease;
  width: 100%;
  font-size: 1.5rem;
  line-height: 36px;
  flex-direction: column;
  color: #fff;
  padding: 60px 30px;

  p {
    margin-bottom: 15px;
  }

  a {
    background-color: #fff;
    border-radius: 20px;
    max-width: 120px;
    font-size: 1rem;
    padding: 8px 35px;
    text-decoration: none;
  }
`

const CoverGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: '. . . .';
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    .cover {
      position: relative;
      height: 260px;
      width: 100%;
      ${CoverImg} {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute !important;
        top: 0;
        left: 0;
      }
      &:hover {
        transform: translateY(20px)
        &:before {
          opacity: 1;
        }
        ${CoverInfo} {
          opacity: 1;
          transform: translateY(0px);
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        z-index: 2;
        transition: opacity ease 0.5s;
        opacity: 0;
      }
    }
  }
`
const chunk = (array, size) => {
  const chunkedArr = []
  let index = 0
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArr
}
const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max))
}

const Covers = ({ covers }) => {
  const carouselItems = chunk(covers, 6)
  return (
    <Wrapper>
      <CoversCarousel>
        <Carousel controls={false} indicators={false}>
          {carouselItems.map((carouselItem, idx) => {
            return (
              <Carousel.Item key={`carousel-item-${idx}`}>
                <CoverGrid>
                  {carouselItem.map((cover, id) => {
                    const { thumbnailImage } = cover
                    return (
                      <div key={`cover-${id}`}>
                        <Link to={`folio${cover.slug}`}>
                          <CoverImg fluid={thumbnailImage.fluid} />
                        </Link>
                      </div>
                    )
                  })}
                </CoverGrid>
              </Carousel.Item>
            )
          })}
        </Carousel>
        <div className="carouselIcon">
          <CarouselHand />
          <p>- Desliza para ver más -</p>
        </div>
      </CoversCarousel>
      <MediumCovers>
        <CoverGrid>
          {covers.map((cover, id) => {
            const { thumbnailImage } = cover
            return (
              <div key={id} className="cover">
                <Link to={`folio${cover.slug}`}>
                  <CoverImg fluid={thumbnailImage.fluid} />
                </Link>
              </div>
            )
          })}
        </CoverGrid>
      </MediumCovers>
      <LargeCovers>
        <CoverGrid>
          {covers.map((cover, id) => {
            const { thumbnailImage } = cover
            const duration = 800
            return (
              <Fade key={id} duration={duration} delay={getRandomInt(11) * 100}>
                <div className="cover">
                  <CoverImg fluid={thumbnailImage.fluid} />
                  <CoverInfo>
                    <h3>{cover.title.title}</h3>
                    <p>{cover.category}</p>
                    <Link to={`folio${cover.slug}`}>Ver folio</Link>
                  </CoverInfo>
                </div>
              </Fade>
            )
          })}
        </CoverGrid>
      </LargeCovers>
    </Wrapper>
  )
}

export default Covers
