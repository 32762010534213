import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

// @components
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Covers from '../components/Covers'
import ContactForm from '../components/ContactForm'
import Section from '../components/Section'
import SocialMedia from '../components/SocialMedia'
import useStaticMetadata from '../hooks/useMetadata'

const Contacto = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: flex;
    justify-content: flex-end;
  }
`

const Home = ({ data }) => {
  const { body, folios } = data.contentfulHomePage
  const { home } = useStaticMetadata()
  return (
    <Layout>
      <SEO
        title={home.title}
        description={home.description}
        image="/images/meta-homepage.jpg"
      />
      <Covers covers={folios} />
      <Container>
        {body &&
          body.map((section, id) => (
            <Section
              key={id}
              title={section.title}
              body={section.body}
              margin={'0'}
            />
          ))}
        <Section title="Contacto">
          <Contacto>
            <ContactForm />
            <SocialMedia />
          </Contacto>
        </Section>
      </Container>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    contentfulHomePage {
      id
      slug
      title
      body {
        id
        title
        body {
          id
          json
        }
      }
      folios {
        id
        category
        slug
        title {
          title
        }
        thumbnailImage {
          fluid(maxHeight: 360) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`
